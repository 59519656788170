import React from "react"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="Error 404" />
    <h1>404</h1>
    <p>
      Lo sentimos...
      <br />
      no encontramos lo que buscabas.
    </p>
  </>
)

export default NotFoundPage
